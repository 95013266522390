import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getSingleJobData } from '../Printing/Actions/printing.action';
import GenerateQrStyle from './GenerateQr.module.css'
import { Box, Button, Image, Text } from '@chakra-ui/react';
import { QRCode } from 'react-qrcode-logo';

export const GenerateQr: React.FC = () => {
  const { jobid } = useParams();
  const [loading, setLoading] = useState(false); // eslint-disable-line
  const [data, setData] = useState<any>({}) // eslint-disable-line
  const [compiledData, setCompiledData] = useState<any>([]) // eslint-disable-line

  const getJobData = async () => {
    setLoading(true);
    if (jobid) {
      try {
        let data: any = await getSingleJobData(jobid, setData);
        if (data) {
          setData(data);
        }

        console.log(data)

        let rawData = data.data.map((el: any) => {
          return {
            _id: el._id,
            benId: el?.benId,
            subscriberName: el?.subscriberName,
            benName: el?.personalInfo?.fullName,
            relation: el?.personalInfo?.relationshipToSubscriber || "",
            qrLabel: el?.personalInfo?.fullName + " C/o " + el?.subscriberName,
            qrInput: `https://www.pococare.com/${el.benId}`,
            status: el.status,
            companyName: el?.companyName || "",
            mobile: el?.personalInfo?.mobile?.number || "Mobile : N/A",
            address: el.address || null,
            subscriberId: el?.subscriberId || "",
            isDuplicate: el?.isDuplicate || false
          }
        })

        setCompiledData(rawData);
        setLoading(false);
      } catch (error) {
        console.log("Error", error);
        setLoading(false);
      }

    }
  }

  useEffect(() => {
    getJobData();
  }, [jobid]) // eslint-disable-line

  const downloadQR = () => {
    window.print();
  }

  return (
    <Box>
      <div id='print-button-container' className={GenerateQrStyle.printBtn}>
        <Button onClick={downloadQR} colorScheme='telegram'>Print QR Codes</Button>
      </div>
      <div className={GenerateQrStyle.main}>
        {
          compiledData && compiledData.map((el: any, index: number) => {
            return <div className={GenerateQrStyle.stickerBox}>
              <div className={GenerateQrStyle.sticker}>
                <div className={GenerateQrStyle.nameContainer}>
                  <text>
                    {el?.benName?.substring(0, 20) + " C/O " + el?.subscriberName?.substring(0, 20)}
                  </text>
                </div>
                <hr className={GenerateQrStyle.hr} />
                <div className={GenerateQrStyle.logoContainer}>
                  <div>
                    <Image width={"60px"} src='/Images/warnIcon.png' alt='Pococare logo' />
                  </div>
                  <div>
                    <Image width={"60px"} src='/Images/newLogo.png' alt='Pococare logo' />
                  </div>
                </div>
                <hr className={GenerateQrStyle.hr} />
                <div className={GenerateQrStyle.upperTextContainer}>
                  <Text>
                    Please scan if you find me in any medical emergency !
                  </Text>
                </div>
                <hr className={GenerateQrStyle.hr} />
                <div className={GenerateQrStyle.qrContainer}>
                  <QRCode
                    eyeColor={"black"}
                    eyeRadius={2}
                    // logoImage={"/Images/logoTransparent.png"}
                    size={95}
                    ecLevel="L"
                    logoWidth={20}
                    logoPadding={1}
                    logoPaddingStyle={"square"}
                    qrStyle="dots"
                    fgColor="black"
                    logoOpacity={5}
                    value={"https://pococare.com/trigger-emergency/" + el.benId}
                  />
                </div>
                <hr className={GenerateQrStyle.hr} />
                <div className={GenerateQrStyle.upperTextContainer}>
                  <Text>
                    or Call
                  </Text>
                </div>
                <hr className={GenerateQrStyle.hr} />
                <div className={GenerateQrStyle.footerContainer}>
                  {/* <div>
                    <Image width={"30px"} src='/Images/phoneIcon.png' alt='Pococare logo' />
                  </div> */}
                  <div className={GenerateQrStyle.phoneContainer}>
                    <Text height={"15px"}>
                      080-45574270
                    </Text>
                    <Text>
                      080-69808950
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
    </Box>
  )
}
