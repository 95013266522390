// import SideBar from "../../GlobalComponent/SideBar/SideBar";
import style from "./BenAddressData.module.css";
import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../GlobalComponent/CustomTable/CustomTable";
import { graphQlPost } from "../../Http/Index";
import { CustomeCheckBox as CustomCheckBox } from "../../GlobalComponent/FilterCheckBox/CustomeCheckBox";
import { CustomInput } from "../../GlobalComponent/CustomInput/CustomeInput";
import { CustomPopup } from "../../GlobalComponent/CustomPopup/CustomPopup";
import { generateDynamicQuery } from "../../graphQl_Query/graphQl_query";
import { exportBenDataByFilter } from "./Action/benDataExportAsExcel";
import { initialStateForOutputField, relationShipValues } from "./Action/utils";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import { EmptyBeneficiaryFilter } from "../../GlobalComponent/EmptyBeneficiaryFilters/EmptyBeneficiaryFilters";
import {
  Select,
  HStack,
  IconButton,
  Text,
  Heading,
  Button,
  Flex,
} from "@chakra-ui/react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { getCompanyList } from "./Action/getAllCompany";
import { CustomSelect } from "../../GlobalComponent/CustomSelect/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { ShowAlert } from "../../GlobalComponent/Alerts/Alert";
import { addBeneficiariesToJob } from "../Printing/Actions/printing.action";
//eslint-disable-next-line
import { downloadBenDashBoardData, handleUpdateWaDoc } from "./Action/actions";
import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
import { generateSaSToken } from "../../Helper/helper";
import { addBenToEmailJob } from "../EmailBulkMessage/action/addBenToJob";
// import { CustomButton } from "../../GlobalComponent/CustomButton/CustomButton";
const emptyDetailsFields: any = {
  noAddress: "address",
  noEmContact: "emergencyContacts",
  noInsurance: "insurancePolicies",
  noMedicalDet: "medicalInfo",
  noPersonalInfo: "personalInfo",
  getAllBenData: "benData",
  noPreferredHospital: "preferredHospital",
  isDeleted: "isDeleted",
  callingList: "callingList",
  activatedBen: "activatedBen",
  notActivatedBen: "notActivatedBen",
  sameAsSubscriberNumber: "sameAsSubscriberNumber",
  notSameAsSubscriberNumber: "notSameAsSubscriberNumber",
  qrDispatched: "qrDispatched",
  qrNotDispatched: "qrNotDispatched"
};

interface Node {
  id: number;
  label: string;
  value: string;
  children?: Node[];
}
const BenAddressData = () => {
  const searchRef = useRef(null);
  const { jobid } = useParams();
  const { jobId, emailJobId } = useParams();
  const navigate = useNavigate();
  const [getBenEmptyDetails, setBenEmptyDetails] = useState({
    noAddress: false,
    noEmContact: false,
    noInsurance: false,
    noMedicalDet: false,
    noPersonalInfo: false,
    noPreferredHospital: false,
    isDeleted: false,
    callingList: false,
    activatedBen: false,
    notActivatedBen: false,
    sameAsSubscriberNumber: false,
    notSameAsSubscriberNumber: false,
    qrDispatched: false,
    qrNotDispatched: false
  });
  const [getBenCompletedDetails, setBenCompletedDetails] = useState({
    address: false,
    emergencyContacts: false,
    insurancePolicies: false,
    medicalInfo: false,
    personalInfo: false,
    preferredHospital: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companyList, setCompanyList] = useState([]);
  const [selectedBenList, setSelectedBenList] = useState([]);
  //eslint-disable-next-line
  const [SaSToken, setSaSToken] = useState(localStorage.getItem("sasToken"));
  //serchCriteria?
  const [iSearchByBen, setIsSearByBen] = useState({
    beneficiary: true,
    subscriber: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  //eslint-disable-next-line
  const [reqSearchFieldValue, setSearchFieldValue] = useState({
    benId: "",
    email: "",
    name: "",
    PhoneNumber: "",
    city: "",
    pinCode: ""
  });
  ///for companySelected
  const [companySelected, setCompanySelected] = useState("");
  //select relation
  const [benRelation, setBenRelation] = useState("");

  ////outputField_State//
  const [selectedFields, setSelectedFields] = useState<Node[]>(() => {
    return initialStateForOutputField;
  });
  //updateOutputField//
  const [checkedTree, setCheckedTree] = useState<any>(
    jobid || jobId
      ? [
        "personalInfo.dateOfBirth",
        "personalInfo.relationshipToSubscriber",
        "personalInfo.fullName",
        "personalInfo.gender",
        "personalInfo.email",
        "personalInfo.mobile",
        "address.addressLine1",
        "address.addressLine2",
        "address.landmark",
        "address.city",
        "address.state",
        "address.country",
        "address.pinCode",
        "address.label",
      ]
      : [
        "personalInfo.dateOfBirth",
        "personalInfo.relationshipToSubscriber",
        "personalInfo.fullName",
        "personalInfo.gender",
        "personalInfo.email",
        "personalInfo.mobile",
      ]
  );
  ///expandOutputField
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [btnLoading, setBtnLoading] = useState(false);
  const [expandedTree, setExpandedTree] = useState(["personalInfo"]);
  const [isOutputModalOpen, setIsOutputModalOpen] = useState(false);
  const [response, setResponse] = useState<any>([]);
  const [handleDate, setHandleDate] = useState({
    createdDateFrom: "",
    createdDateTo: "",
    updatedDateFrom: "",
    updatedDateTo: "",
  });
  const posReq = async (query: any, variables: any) => {
    try {
      // console.log('YOUR_QUERY:', query,variable);
      setIsLoading(true);
      const res = await graphQlPost({ query, variables });
      setIsLoading(false);
      await generateSaSToken();
      setSaSToken(localStorage.getItem("sasToken"))

      // console.log("res:", res);
      if (res) {
        // console.log('res:', res);
        return res?.data?.data?.beneficiaries;
      }
    } catch (e) {
      alert("some Technical Issue in getting Ben Data");
      // console.log("e", e);
      setIsLoading(false);
    }
  };

  const handleCatchEmptyDetails = (label: string, checked: boolean) => {
    setBenEmptyDetails((prev) => ({
      ...prev,
      [label]: checked,
    }));

    // console.log('label:', label);
    // preSelectOutput(label);////////////////////////
    if (label === "callingList" && checked) {
      // alert("callingListClicked");
      setCheckedTree([
        "personalInfo.fullName",
        "subscriberDetails.subscriberName",
        "subscriberDetails.subscriberMobile",
        "company.name",
        "personalInfo.relationshipToSubscriber",
        "personalInfo.mobile",
        "address.addressLine1",
        "address.addressLine2",
        "address.landmark",
        "address.city",
        "address.state",
        "address.country",
        "address.pinCode",
        "address.label",
      ]);
    }
    else if (label === "callingList" && !checked) {
      setCheckedTree([
        "personalInfo.dateOfBirth",
        "personalInfo.relationshipToSubscriber",
        "personalInfo.fullName",
        "personalInfo.gender",
        "personalInfo.email",
      ])
    }
    if (label !== "isDeleted" && label !== "callingList") {
      const finalVal: any = { ...getBenCompletedDetails, [emptyDetailsFields[label]]: checked };
      //  console.log('finalVal:', finalVal,emptyDetailsFields[label]);
      preSelectOutput({ label: emptyDetailsFields[label], allObject: finalVal });
    }
  };
  const handleCatchFilledDetails = (label: string, checked: boolean) => {

    setBenCompletedDetails((prev) => ({
      ...prev,
      [label]: checked,
    }));
    const finalVal: any = { ...getBenCompletedDetails, [label]: checked };
    // console.log('finalVal:', finalVal,label);
    preSelectOutput({ label, allObject: finalVal });

  }
  const handleCatchSearCriteria = (label: any[]) => {
    let fieldName = label[0];
    let fieldValue = label[1];

    let data = {
      ...iSearchByBen,
      [fieldName]: !fieldValue,
    };
    setIsSearByBen(data);
  };

  const handleSearch = async (
    pageNumber: any,
    pageSize: any,
    downloadExcel = false
  ) => {

    const response = handlePayloadBuild(pageNumber, pageSize, downloadExcel);
    if (response) {
      const res = await posReq(response.queryString, response.variables);
      setResponse(res);
      setIsLoading(false);
    } else {
      alert("Please Select the Filter And OutPut Field");
    }
  };

  const handlePayloadBuild = (
    pageNumber: any,
    pageSize: any,
    downloadExcel = false,
    isAllDataRequired = false
  ) => {
    const res = checkIsSelectedEmptyBen(getBenEmptyDetails);
    // console.log('getBenCompletedDetails:', getBenCompletedDetails);
    const getFilledBenDetails = checkIsSelectedEmptyBen(getBenCompletedDetails);
    // console.log('getFilledBenDetails:', getFilledBenDetails);//   
    const emptyField = convertFieldNames(res);
    console.log('emptyField:', emptyField);
    const filteredEmptyField = emptyField.filter((item: any) => item !== "activatedBen" && item !== "notActivatedBen" && item !== "sameAsSubscriberNumber" && item !== "qrNotDispatched" && item !== "qrDispatched" && item !== "notSameAsSubscriberNumber");
    console.log('filteredEmptyField:', filteredEmptyField);
    // const filledField = convertFieldNames(getFilledBenDetails);//
    // console.log('filledField:', filledField);// 
    const outputField = generateOutputField();
    // console.log("outputField:", outputField);
    const variables = {
      page: pageNumber,
      limit: pageSize,
      findEmptyDetailsBen: filteredEmptyField,
      findFullyCompletedBeneficiaries: getFilledBenDetails,
      companyName: companySelected,
      name: reqSearchFieldValue.name,
      email: reqSearchFieldValue.email,
      mobile: reqSearchFieldValue.PhoneNumber,
      city: reqSearchFieldValue.city,
      pinCode: reqSearchFieldValue.pinCode,
      beneficiary: iSearchByBen.beneficiary,
      subscriber: iSearchByBen.subscriber,
      benRelation: benRelation,
      benId: reqSearchFieldValue.benId,
      isAllDataRequired: isAllDataRequired,
      sameAsSubscriberNumber: emptyField.includes("sameAsSubscriberNumber") ? true : false,
      notSameAsSubscriberNumber: emptyField.includes("notSameAsSubscriberNumber") ? true : false,
      qrDispatched: emptyField.includes("qrDispatched") ? true : false,
      qrNotDispatched: emptyField.includes("qrNotDispatched") ? true : false,
      downloadExcel: downloadExcel,
      activatedBen: emptyField.includes("activatedBen") ? true : false,
      notActivatedBen: emptyField.includes("notActivatedBen") ? true : false,
      createdFrom: handleDate.createdDateFrom
        ? new Date(handleDate.createdDateFrom).toISOString()
        : "",
      createdTo: handleDate.createdDateFrom
        ? new Date(handleDate.createdDateTo).toISOString()
        : "",
      updatedFrom: handleDate.updatedDateFrom
        ? new Date(handleDate.updatedDateFrom).toISOString()
        : "",
      updatedTo: handleDate.updatedDateTo
        ? new Date(handleDate.updatedDateTo).toISOString()
        : "",
    };
    // console.log(
    //   "ssdsdsd",
    //   (reqSearchFieldValue.PhoneNumber ||
    //     reqSearchFieldValue.email ||
    //     reqSearchFieldValue.name ||
    //     iSearchByBen.subscriber ||
    //     iSearchByBen.beneficiary ||
    //     companySelected ||
    //     benRelation ||
    //     emptyField.length) &&
    //     outputField !== ""
    // );
    if (
      (reqSearchFieldValue.PhoneNumber !== "" ||
        reqSearchFieldValue.email !== "" ||
        reqSearchFieldValue.name !== "" ||
        iSearchByBen.subscriber ||
        iSearchByBen.beneficiary ||
        companySelected !== "" ||
        benRelation !== "" ||
        emptyField.length) &&
      outputField !== ""
    ) {
      const getPayload = generateDynamicQuery(variables, outputField);
      // setPageNumber(1);
      // setPageSize(10);
      return getPayload;
    }
  };

  const preSelectOutput = ({ label, allObject }: any) => {
    // selectedFields
    // console.log("check",label,allObject);

    // console.log('outputField:', outputField);
    if (label === "medicalInfo" || label === "personalInfo") {
      return
    }
    const outputField: any = getSelectedFilterOutputField(label);
    // console.log('outputField:', outputField,allObject[label]);
    if (allObject[label] && outputField) {
      return setCheckedTree((prev: any) => {
        return [...prev, ...outputField];
      })
    } else {
      // console.log('outputField:', outputField);
      return outputField && !outputField.includes("personalInfo.dateOfBirth") && setCheckedTree((prev: any) => {
        return prev.filter((el: any) => !outputField.includes(el));
      })
    }
  }
  // console.log('checkedTree:', checkedTree)
  const getSelectedFilterOutputField = (selectedFilter: any) => {
    console.log('selectedFilter:', selectedFilter, selectedFields);
    const matchedField = selectedFields.find((availableField: any) => selectedFilter === "activatedBen" || selectedFilter === "notActivatedBen" ? availableField.label === "address" : availableField.label === selectedFilter);
    if (matchedField) {
      const res = matchedField?.children?.map((el) => el.value)
      return res;
    }



  }
  // console.log('checkedTree:', checkedTree);
  const convertFieldNames = (res: any) => {
    let emptyStr: any[] = [];
    // console.log('res:', res);
    res?.forEach((el: any) => {
      emptyStr.push(emptyDetailsFields[el]);
    });

    return emptyStr;
  };
  ///need update
  const generateOutputField = () => {
    // First need to separate parent-child
    const res = checkedTree.map((el: any) => {
      const [parent, ...child] = el.split(".");
      // console.log(parent,child);
      return { parent, child };
    });

    // Then group by parent-:[child,,,,]
    const groupedRes = res.reduce((acc: any, { parent, child }: any) => {
      if (parent === "preferredHospital") {
        // Treat "preferredHospital" as a separate entry and nest it under "address"
        const existingEntry = acc.find(
          (entry: any) => entry.parent === "address"
        );
        // const emContact=acc.find((entry: any) => entry.parent === "emergencyContacts");

        if (existingEntry) {
          existingEntry.child.push(`${parent} {\n${child.join("\n")}\n}`);
        } else {
          acc.push({
            parent: "address",
            child: [`${parent} {\n${child.join("\n")}\n}`],
          });
        }
      } else {
        const existingEntry = acc.find((entry: any) => entry.parent === parent);

        if (existingEntry) {
          existingEntry.child.push(...child);
        } else {
          acc.push({ parent, child });
        }
      }

      return acc;
    }, []);

    // Create GraphQL query
    const createQlPayload = groupedRes
      .map(({ parent, child }: any) => {
        if (parent === "emergencyContacts" || parent === "personalInfo") {
          // console.log(child, "childdddd", child.includes("mobile"));
          if (child.includes("mobile")) {
            return `${parent} {\n${child.join(
              "\n"
            )}{ number, countryCode }\n  whatsappMobile { number, countryCode }\n}\n`;
          } else {
            return `${parent} {\n${child.join("\n")}\n}`;
          }
          // return `${parent} {\n${child.join("\n")}\n  mobile { number, countryCode }\n  whatsappMobile { number, countryCode }\n}\n`;

          // Always include nested fields for emergencyContacts
        } else {
          return `${parent} {\n${child.join("\n")}\n}\n`;
        }
      })
      .join("");

    // console.log("createQlPayload:", createQlPayload);
    return createQlPayload;
  };

  const checkIsSelectedEmptyBen = (getBenEmptyDetails: any) => {
    let payload: any[] = [];
    for (let [key, value] of Object.entries(getBenEmptyDetails)) {
      if (key !== "callingList") {
        if (value) {
          payload.push(key);
        }
      }
    }
    return payload;
  };

  const handleCatchInput = (updatedField: { label: string; value: string }) => {
    // console.log("lable:", updatedField);
    let { label, value } = updatedField;

    setSearchFieldValue((prev) => ({ ...prev, [label]: value }));
  };

  const handleOutputModal = () => {
    setIsOutputModalOpen(!isOutputModalOpen);
  };
  const handleCheckboxChange = (parent: any, field: any) => {
    // console.log("parent:", parent, field);
    if (field === "all") {
      setSelectedFields((prevSelectedFields: any) => {
        const allChecked = !prevSelectedFields[parent]["all"];
        // console.log("allChecked:", allChecked);
        const updatedFields: any = {
          ...prevSelectedFields,
          [parent]: {
            ...prevSelectedFields[parent],
            all: allChecked,
            ...Object.keys(prevSelectedFields[parent]).reduce((acc, key) => {
              acc[key] = allChecked;
              return acc;
            }, {} as { [key: string]: boolean }),
          },
        };
        return updatedFields;
      });
    } else {
      setSelectedFields((prevSelectedFields: any) => {
        const updatedFields: any = {
          ...prevSelectedFields,
          [parent]: {
            ...prevSelectedFields[parent],
            [field]: !prevSelectedFields[parent][field],
          },
        };
        // Check if all child checkboxes are checked, update the "all" checkbox accordingly
        return updatedFields;
      });
    }
  };
  const handleCatchBenType = (value: any) => {
    setBenRelation(value);
  };

  const clearSelectedOutputField = () => {
    setCheckedTree(
      jobid || jobId
        ? [
          "personalInfo.dateOfBirth",
          "personalInfo.relationshipToSubscriber",
          "personalInfo.fullName",
          "personalInfo.gender",
          "personalInfo.email",
          "personalInfo.mobile",
          "address.addressLine1",
          "address.addressLine2",
          "address.landmark",
          "address.city",
          "address.state",
          "address.country",
          "address.pinCode",
          "address.label",
        ]
        : [
          "personalInfo.dateOfBirth",
          "personalInfo.relationshipToSubscriber",
          "personalInfo.fullName",
          "personalInfo.gender",
          "personalInfo.email",
          "personalInfo.mobile",
        ]
    );
  };

  const handleCancelPopup = () => {
    clearSelectedOutputField();
    handleOutputModal();
  };
  const handleDownloadExcel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonValue = (e.target as HTMLButtonElement).value;
    if (selectedBenList.length) {
      // console.log('selectedBenList:', selectedBenList)
      if (jobId) {
        await handleUpdateWaJob({ jobId, data: selectedBenList, navigate });
      } else if (emailJobId) {
        await addBenToEmailJob(emailJobId, selectedBenList)
      }
      else if (jobid) {
        // alert("Please Select the Field");
        await printingAddJob(selectedBenList || []);
      } else {
        const response = handlePayloadBuild(pageNumber, pageSize, true);
        if (response) {
          const res = await posReq(response.queryString, response.variables);
          if (!res?.data?.length) {
            ShowAlert("warning", "Result is Empty Please Select the Field");
          } else {
            //eslint-disable-next-line

            await exportBenDataByFilter(res?.data || [], SaSToken);
          }
        }
      }
    } else {
      const response = handlePayloadBuild(pageNumber, pageSize, true, buttonValue === "Add All Ben To Job" ? true : false);
      if (response) {
        const res = await posReq(response.queryString, response.variables);
        setLoading(false);
        if (!res?.data?.length) {
          ShowAlert("warning", "Result is Empty Please Select the Field");
        } else {
          // console.log("response:from else condition", response);
          if (jobid) {

            await printingAddJob(res?.data || []);
          } else if (jobId) {
            await handleUpdateWaJob({ jobId, data: res?.data, navigate });
          } else if (emailJobId) {
            await addBenToEmailJob(emailJobId, res?.data)
          }
          else {
            // console.log("res?.data:", res?.data);
            await exportBenDataByFilter(res?.data || [], SaSToken);
          }
        }
      }
    }
  };
  const handleUpdateWaJob = async ({ jobId, data, navigate }: any) => {
    try {
      setLoading(true);
      await handleUpdateWaDoc({ jobId, data, navigate });
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      alert(e?.response?.data?.message || "some technical issue");
      setLoading(false);
    }
  };
  const printingAddJob = async (data: any) => {
    try {
      setLoading(true);
      await addToJob(data || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const addToJob = async (data: any) => {
    // console.log('data:', data);
    let benIds = data?.filter((el: any) => {
      // Check if either _id or benId property exists
      return el?._id || el?.benId;
    }).map((el: any) => {
      // Map the elements to get benId value
      return {
        benId: el?._id || el?.benId
      };
    });
    // console.log(benIds, "********************");
    const additionPayload = {
      beneficiaryList: benIds,
    };
    try {
      if (jobid) {
        await addBeneficiariesToJob(jobid, additionPayload);
        ShowAlert("success", "Beneficiaries Added Successfully");
        return navigate("/printing-details/" + jobid);
      }
    } catch (error) {
      ShowAlert("warning", "Something went wrong");
    }
  };
  const handleClearEmptyDetails = (defaultField: any) => {
    // console.log('defaultField:', defaultField)
    setBenEmptyDetails(defaultField);
  };
  const handleClearFilter = () => {
    // console.log("check");
    handleClearEmptyDetails({
      noAddress: false,
      noEmContact: false,
      noInsurance: false,
      noMedicalDet: false,
      noPersonalInfo: false,
      noPreferredHospital: false,
      isDeleted: false,
      callingList: false,
      activatedBen: false,
      notActivatedBen: false,
      sameAsSubscriberNumber: false,
      notSameAsSubscriberNumber: false,
      qrDispatched: false,
      qrNotDispatched: false
    });
    setBenCompletedDetails(
      {
        address: false,
        emergencyContacts: false,
        insurancePolicies: false,
        medicalInfo: false,
        personalInfo: false,
        preferredHospital: false,
      }
    )
    setSearchFieldValue({
      benId: "",
      email: "",
      name: "",
      PhoneNumber: "",
      city: "",
      pinCode: ""
    });

    clearSelectedOutputField();
    setHandleDate({
      createdDateFrom: "",
      createdDateTo: "",
      updatedDateFrom: "",
      updatedDateTo: "",
    });
    setBenRelation("");
    setResponse([]);
    setIsSearByBen({
      beneficiary: true,
      subscriber: true,
    });
    setPageSize(10);
    setPageNumber(1);
    setCompanySelected("");
    setIsLoading(false);
    setCompanyList([]);
  };
  const handleCatchDate = (type: any, value: any) => {
    // console.log(type, value);
    setHandleDate((prev) => ({
      ...prev,
      [type]: value,
    }));
  };
  const handlePageSizeChange = (value: string) => {
    setPageSize(Number(value));
    setPageNumber(1);
    const pageSize = Number(value);
    const pageNumber = Number(1);
    handleSearch(pageNumber, pageSize, false);
  };
  const handleDataChange = (type: string) => {
    if (type === "increasePage") {
      console.log('pageNumber:', pageNumber);
      setPageNumber(pageNumber + 1);
      handleSearch(pageNumber + 1, pageSize, false);
    }

    if (type === "decreasePage") {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
        handleSearch(pageNumber - 1, pageSize, false);
      }
    }
  };
  const getallCompany = async () => {
    try {
      const getCompany = await getCompanyList();
      const companyObj = arrayToObject(getCompany?.data);
      setCompanyList(companyObj);
    } catch (e: any) {
      alert(
        e?.response?.message || "Some Technical Issue in getting Templates"
      );
    }
  };

  const arrayToObject = (getCompany: any) => {
    const companyListObj: any = {};

    getCompany.forEach((el: any, i: number) => {
      companyListObj[el.companyName] = el.companyName;
    });
    return companyListObj;
  };

  const handleDownloadCallingList = async () => {
    setBtnLoading(true);
    await downloadBenDashBoardData();
    setBtnLoading(false);
  };

  useEffect(() => {
    const getLoginStatus = localStorage.getItem("isLogin");
    if (getLoginStatus === "false") {
      return navigate("/login")

    }
    if (!SaSToken) {

      (async () => await generateSaSToken())();
      setSaSToken(localStorage.getItem("sasToken"))
    }
    return () => {
      handleClearFilter();
      setCheckedTree([
        "personalInfo.dateOfBirth",
        "personalInfo.relationshipToSubscriber",
        "personalInfo.fullName",
        "personalInfo.gender",
        "personalInfo.email",
        "personalInfo.mobile",
      ]);
      setResponse([]);
    };


  }, [jobid]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('getCompany:', companyList);
  // console.log("handleDate:", handleDate);
  return (
    <>
      <div>
        {jobid ? (
          <Heading textAlign={"center"}> Filter Beneficiary </Heading>
        ) : (
          <p>Get Ben Data By Filter Data</p>
        )}
        <EmptyBeneficiaryFilter
          // formattedName={emptyDetailsNames}
          getBenEmptyDetails={getBenEmptyDetails}
          onCheckboxChange={handleCatchEmptyDetails}
        />
        <div className={style.gap} >
          <p>Get Ben Completed Data</p>
          <EmptyBeneficiaryFilter
            getBenEmptyDetails={getBenCompletedDetails}
            onCheckboxChange={handleCatchFilledDetails}
          />
        </div>

        <div className={style.flex_filter}>
          <div>
            <p>Filter By CreatedDate</p>
            <div style={{ display: "flex", gap: "1rem" }}>
              <input
                style={{ border: "1px solid black" }}
                value={handleDate.createdDateFrom}
                onChange={(e) =>
                  handleCatchDate("createdDateFrom", e.target.value)
                }
                type="date"
              />
              <input
                style={{ border: "1px solid black" }}
                type="date"
                value={handleDate.createdDateTo}
                onChange={(e) =>
                  handleCatchDate("createdDateTo", e.target.value)
                }
              />
            </div>
          </div>
          <div>
            <p>Filter By UpdatedDate</p>
            <div style={{ display: "flex", gap: "1rem" }}>
              <input
                style={{ border: "1px solid black" }}
                value={handleDate.updatedDateFrom}
                onChange={(e) =>
                  handleCatchDate("updatedDateFrom", e.target.value)
                }
                type="date"
              />
              <input
                style={{ border: "1px solid black" }}
                type="date"
                value={handleDate.updatedDateTo}
                onChange={(e) =>
                  handleCatchDate("updatedDateTo", e.target.value)
                }
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Select Beneficiary RelationShip</label>
            <select
              onChange={(e) => handleCatchBenType(e.target.value)}
              value={benRelation}
              style={{ border: "1px solid black" }}
            >
              {relationShipValues.map((el: any) => {
                return <option value={el.rel}>{el.rel}</option>;
              })}
            </select>
          </div>
        </div>
        <div className={style.flex_Search}>
          <div>
            <p>Select Field To Search</p>
            {Object.entries(iSearchByBen).map((el: any) => {
              //  const [label, value] = el;
              return (
                <React.Fragment key={el}>
                  <CustomCheckBox
                    label={el}
                    checked={el[1]}
                    onChange={handleCatchSearCriteria}
                  />
                </React.Fragment>
              );
            })}
          </div>
          <div className={style.grid}>
            <div>
              <label>Select Company:</label>
              <CustomSelect
                label="Select Company"
                getOptionList={getallCompany}
                optionList={companyList}
                setCompanySelected={setCompanySelected}
              />
            </div>
            {Object.entries(reqSearchFieldValue).map((el: any) => {
              const [label, value] = el;
              return (
                <React.Fragment key={label}>
                  <CustomInput
                    label={label}
                    value={value}
                    onChange={handleCatchInput}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <br></br>
        <br></br>
        <div className={style.felx_btn}>
          <div>
            <button onClick={handleOutputModal} className={style.button}>
              Select Output Fields
            </button>
          </div>
          <div>
            <button
              onClick={() => handleSearch(pageNumber, pageSize, false)}
              className={style.button}
              ref={searchRef}
            >
              Search
            </button>
          </div>
          <div>
            <button onClick={handleClearFilter} className={style.button}>
              Clear Filter
            </button>
          </div>

          <div>
            {jobid || jobId || emailJobId ? (
              response?.data?.length > 0 ? (
                <Flex gap={"20px"}>
                  <Button
                    onClick={handleDownloadExcel}
                    colorScheme={"facebook"}
                    variant={"solid"}
                    isLoading={loading}
                  >
                    Add Beneficiary To Job
                  </Button>
                  <Button
                    onClick={handleDownloadExcel}
                    colorScheme={"green"}
                    variant={"solid"}
                    isLoading={loading}
                    value={"Add All Ben To Job"}
                  >
                    Add All Ben To Job
                  </Button>
                </Flex>
              ) : null
            ) : (null
              // <button onClick={handleDownloadExcel} className={style.button}>
              //   Download Excel
              // </button>
            )}
          </div>
          {jobid || jobId || emailJobId ? (
            <></>
          ) : (
            <CustomButton
              customStyle={style.button}
              label="Download Calling List"
              handleClick={handleDownloadCallingList}
              additionalData={""}
              loading={btnLoading}
            />
          )}
        </div>

        {response?.data && response?.data?.length > 0 ? (
          <>
            <div className={style.wrapperTable}>
              <CustomTable
                data={response?.data}
                setSelectedBenList={setSelectedBenList}
                searchRef={searchRef}

              />
              <HStack justifyContent={"flex-end"}>
                <HStack>
                  <Select
                    value={pageSize}
                    onChange={(e: any) => handlePageSizeChange(e.target.value)}
                  >
                    <option value="10">10 </option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Select>
                </HStack>
                <HStack>
                  <IconButton
                    colorScheme="blue"
                    aria-label="Search database"
                    isDisabled={Number(pageNumber) === 1 ? true : false}
                    onClick={() => handleDataChange("decreasePage")}
                    icon={<GrFormPrevious />}
                  />
                  <Text>
                    {pageNumber +
                      "/" +
                      Math.ceil((response?.count || 10) / pageSize)}
                  </Text>
                  <IconButton
                    colorScheme="blue"
                    aria-label="Search database"
                    isDisabled={response?.hasNextPage ? false : true}
                    onClick={() => handleDataChange("increasePage")}
                    icon={<GrFormNext />}
                  />
                </HStack>
              </HStack>
            </div>
          </>
        ) : (
          <>
            <div className={style.loadingStyle}>
              {isLoading ? (
                <Sentry color="pink" size={100} />
              ) : (
                <>No data Found</>
              )}
            </div>
          </>
        )}
      </div>
      {isOutputModalOpen && (
        <CustomPopup
          handleCancelPopup={handleCancelPopup}
          setCheckedTree={setCheckedTree}
          handleCheckboxChange={handleCheckboxChange}
          modalTitle={"Select Output Fields"}
          selectedFields={selectedFields}
          checkedTree={checkedTree}
          handleCloseModal={handleOutputModal}
          setExpandedTree={setExpandedTree}
          expandedTree={expandedTree}
        />
      )}
    </>
  );
};

export default BenAddressData;
